@import '../../styles/shared.scss';

$zIndex: 0;

.iframe-holder {
  position: relative;
  height: 100vh;
  width: 100vw;
  .bg-cover,
  .bg-iframe,
  .iframe-loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .bg-iframe {
    background-repeat: no-repeat;
    background-size: cover;
    z-index: $zIndex - 1;
  }
  .bg-cover {
    backdrop-filter: blur(0.5rem);
    z-index: $zIndex;
  }
  .iframe-loading-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: $zIndex + 2;
  }
}
.Home {
  .button-group {
    position: fixed;
    z-index: 6;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    .ant-btn {
      line-height: 28px;
      min-width: 150px;
      margin: 0 10px;
      text-transform: uppercase;
      @media (max-width: $breakpoint-xs) {
        min-width: 0;
        margin: 0 5px;
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
