.gallery-preview-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
}

.gallery-preview-item {
  flex-basis: 32%;
}
