@import '../../styles/shared.scss';

.actions-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
  border: 1px solid #fff;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
  opacity: 0.7;
  padding: 10px 15px;
  margin-bottom: 20px;

  @media (orientation: landscape) {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .ant-btn.ant-btn-link {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    -webkit-appearance: none;
    @media (max-width: $breakpoint-sm) {
      height: 32px;
    }
    @media (orientation: landscape) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  i {
    color: #324d75;
    height: 30px;
    width: 35px;
    position: relative;
    text-align: center;
    line-height: 1;
    @media (max-width: $breakpoint-sm) {
      font-size: 22px;
    }
    &:before {
      font-size: 28px;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 30px;
    }
  }
}

.modal-exhibition {
  .ant-modal-close-x {
    @media (max-width: $breakpoint-xs) {
      color: white;
    }
  }
}
