@import "../../styles/shared.scss";
.exhibition-hall-background{
  width: 100%;
  height: 100%;
  position: absolute;
  background-attachment: fixed;
  background-position: center bottom;
  background-size: cover;
  background-repeat: repeat;
  transition: all 0.5s;
}

.ExhibitionContainer {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  height: 100vh;
  .empty-box {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .message-box {
      background: #fff;
      border-radius: 5px;
      padding: 4px 10px;
    }
  }
  .slick-slider {
    position: absolute;
    left: 60px;
    right: 60px;
    top: 0;
    bottom: 0;
    &.un-draggable {
      pointer-events: none;
    }
    @media (max-width: $breakpoint-xs) {
      left: 0;
      right: 0;
    }

    .slick-list {
      height: 100%;
      @media (min-width: $breakpoint-sm){
        margin-left: -60px;
        margin-right: -60px;
      }
    }
    .slick-track {
      height: 100%;
      background-position: center bottom;
      background-size: auto 100%;
      background-repeat: repeat-x;
      min-width: 100%;
    }
  }
  .slick-arrow{
    z-index: 1;
  }
  .slick-dots {
    position: absolute;
    bottom: 20px;
    display: none!important;
    li {
      width: auto;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
      &.slick-active {
        opacity: 1;
      }
    }
    li button {
      background: #fff;
      width: 30px;
      border-radius: 999em;
      height: 4px;
      font-size: 12px;
      line-height: auto;
      padding: 0;
      &:before {
        display: none;
      }
    }
  }
  .slick-prev,
  .slick-next {
    width: 35px;
    height: 35px;
  }
  .slick-prev {
    @media (max-width: $breakpoint-xs) {
      left: 10px;
      z-index: 999;
    }
    &:before {
      font-size: 35px;
      text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    }
  }
  .slick-next {
    right: -10px;
    @media (max-width: $breakpoint-xs) {
      right: 20px;
      z-index: 999;
    }
    &:before {
      font-size: 35px;
      text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    }
  }
}

.ExhibitionContainer-mobile {
  display: flex;
  height: 100vh;
  align-items: center;
}

.slide-exhibition-item {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  height: 100vh;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  @media (orientation: landscape) {
    padding-top: 15px;
    padding-bottom: 60px;
  }
  .view-detail {
    pointer-events: none;
  }
  &__container {
    margin-bottom: 20px;
    display: block;
    height: 67%;
    width: 100%;
    @media (max-width: $breakpoint-sm) {
      height: 55%;
    }
    @media (max-width: $breakpoint-xs) {
      height: 50%;
    }
    @media (orientation: landscape) {
      height: 65%;
    }
  }
  &__image {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
