@import '../../styles/shared.scss';

.ExhibitionGallery {
  display: flex;
  align-items: stretch;

  @media (max-width: $breakpoint-xs) {
    flex-direction: column;
  }

  &__left {
    height: 100%;
    min-width: 50%;
    flex-basis: 50%;
  }
  &__left-inner {
    height: 60vh;
    @media (max-width: $breakpoint-xs) {
      height: 70vw;
    }
  }
  &__right {
    height: 100%;
    min-width: 50%;
    flex-basis: 50%;
    text-align: center;
    @media (max-width: $breakpoint-xs) {
      text-align: left;
    }
  }
  &__right-inner {
    padding: 20px 40px;
    height: 60vh;
    @media (max-width: $breakpoint-xs) {
      padding: 15px;
      height: auto;
    }
  }
  &__group {
    @media (max-width: $breakpoint-xs) {
      display: flex;
      align-items: center;
    }
  }
  &__logo {
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $breakpoint-xs) {
      margin-right: 15px;
      margin-bottom: 0;
    }
    @media (orientation: landscape) {
      margin-bottom: 10px;
    }
  }
  &__company-name {
    color: var(--blue);
    font-size: 26px;
    line-height: 37px;
    font-weight: 500;
    margin-bottom: 10px;
    @media (max-width: $breakpoint-xs) {
      font-size: 18px;
      line-height: 1.1;
      margin-bottom: 0;
    }
    @media (orientation: landscape) {
      font-size: 17px;
      line-height: 1.1;
    }
  }
  &__desc {
    color: #8d8d8d;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 70px;
    @media (max-width: $breakpoint-sm) {
      margin-bottom: 10px;
    }
    @media (max-width: $breakpoint-xs) {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 1.4;
    }
    @media (orientation: landscape) {
      margin-bottom: 10px;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__info__footer {
    margin-top: auto;
  }
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div,
  .slick-slide > div > div,
  .exhibition-gallery-item,
  .exhibition-gallery-item__container,
  .exhibition-gallery-item__image {
    height: 100%;
  }
  .exhibition-gallery-item__image {
    @media (max-width: $breakpoint-xs) {
      border-radius: 20px 20px 0 0;
      overflow: hidden;
    }
  }
  .slick-next:before,
  .slick-prev:before {
    color: var(--blue);
  }
  .slick-dots {
    bottom: -40px;
    @media (max-width: $breakpoint-xs) {
      bottom: 20px;
    }
  }
  .slick-dots li {
    width: 15px;
    height: 15px;
    &.slick-active {
      button {
        background-color: var(--blue);
      }
    }
  }
  .slick-dots li button {
    width: 15px;
    height: 15px;
    background: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    &:before {
      display: none;
    }
  }
}

.exhibition-gallery-item {
  height: 100%;
  &__image {
    position: relative;
    .brand-box {
      background-size: cover;
    }
  }
}

.ActionsContainer {
  border-radius: 10px;
  background-color: var(--blue);
  display: flex;
  justify-content: space-evenly;
  position: relative;
  &:before {
    background-color: rgba(255, 255, 255, 0.1);
    content: '';
    display: block;
    width: 1px;
    position: absolute;
    left: 50%;
    top: 15px;
    bottom: 15px;
  }
  a,
  .ant-btn {
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
    max-height: none;
    opacity: 0.6;
    transition: none;
    display: block;
    width: 100%;
    &:hover,
    &:focus {
      color: #fff;
      opacity: 1 !important;
    }
    @media (max-width: $breakpoint-sm) {
      padding-top: 3px;
      padding-bottom: 3px;
    }
    @media (max-width: $breakpoint-xs) {
      padding-top: 0;
      padding-bottom: 0;
    }
    @media (orientation: landscape) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
