@import '../../styles/shared.scss';

.iframe-container {
  height: 630px;
  max-width: 1120px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 123px;
  @media (max-width: $breakpoint-ipad) {
    max-width: 79%;
    height: 60vh;
    margin-top: 11.5vh;
  }
  @media (max-width: $breakpoint-sm) {
    max-width: 100%;
  }
}

.Workshop {
  height: 100vh;
  background-position: center top;
  background-size: auto 100%;
  background-repeat: no-repeat;
  @media (max-width: $breakpoint-ipad) {
  }
}
