.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.ml-2 {
  margin-left: 2rem;
}
.mr-2 {
  margin-right: 2rem;
}
.pr-2 {
  padding-right: 2em;
}
.pt-1 {
  padding-top: 1rem;
}
.pt-2 {
  padding-top: 2rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.d-inline-block {
  display: inline-block;
}

.position-relative {
  position: relative;
}
