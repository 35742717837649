@import '../../styles/shared';

.Main {
  min-height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .homepage-bg {
    background-position: center;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: none;
  }
  @media screen and (orientation: landscape){
    .bg-landscape{
      display: block;
    }
  }
  @media screen and (orientation: portrait){
    .bg-portrait{
      display: block;
    }
  }
}

.main-container {
  max-width: 600px;
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  @media (max-width: $breakpoint-sm) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .agenda-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    border-radius: 20px;
    background-position: center;
  }
  .enter-button {
    min-width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
  }
}
