.SearchBox {
  position: fixed;
  right: 25px;
  top: 25px;
  height: 42px;
  background-color: #fff;
  border-radius: 9999em;
  border: 1px solid transparent;
  display: block;
  transition: all 0.4s linear;
  width: 40px;

  .anticon.ant-input-search-icon {
    display: none;
  }

  &.show-search {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
    border-color: #d9ba84;
    width: 150px;

    > .search_input {
      opacity: 1;
    }
  }

  .search_input {
    border: 0;
    outline: 0;
    background: none;
    outline: none;
    box-shadow: none;
    line-height: 28px;
    margin-top: 6px;
    border-radius: 999em;
    overflow: hidden;
    /* opacity: 0; */
    padding: 0;
    position: absolute;
    right: 48px;
    left: 20px;
    width: auto;

    .ant-input {
      padding-left: 4px;
    }

    &:focus,
    &:hover {
      outline: none;
      box-shadow: none;
    }
  }

  .search_icon {
    height: 40px;
    width: 40px;
    color: var(--blue);
    position: absolute;
    right: 0;
    top: 0;
  }
}
