@import '../../styles/shared.scss';

.GalleryModal {
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    width: 200px;
  }
  .image-gallery-slide-wrapper.left,
  .image-gallery-slide-wrapper.right {
    width: calc(100% - 210px);
  }
  .image-gallery-thumbnails-container {
    padding: 20px;
  }
  .image-gallery-thumbnail {
    width: 100%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
    margin-bottom: 20px;
    opacity: 0.5;
    @media (max-width: $breakpoint-sm) {
      width: 50px;
    }
  }
  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover,
  .image-gallery-thumbnail:focus {
    border-color: #ffca2c;
    opacity: 1;
  }

  .image-gallery-description {
    display: none;
  }

  &__info {
    background: #fff;
    position: absolute;
    width: calc(100% - 310px);
    left: 50px;
    bottom: -30px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    text-align: center;
    padding: 15px;
    @media (max-width: $breakpoint-sm) {
      box-shadow: none;
      position: relative;
      left: auto;
      bottom: auto;
      width: 100%;
      padding-top: 0;
    }
    &-title {
      color: var(--blue);
      font-weight: bold;
      font-size: 20px;
    }
    &-desc {
      color: #666;
    }
  }
}
