@import '../../styles/shared.scss';

.GalleriesDesktop {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  .empty-box {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slick-slider {
    &.un-draggable {
      pointer-events: none;
      overflow: hidden;
    }
    .slick-track {
      min-width: 100%;
    }
    .slick-next,
    .slick-prev {
      opacity: 0.7;
      color: #fff;
      position: fixed;
      bottom: 100px;
      z-index: 3;
      transform: none;
      top: auto;
      font-size: 50px;
      width: 50px;
      height: 50px;
      @media (max-width: $breakpoint-sm) {
        width: 30px;
        height: 30px;
        font-size: 30px;
        bottom: 60px;
      }
      &:before {
        display: none;
      }
      &:hover,
      &:focus {
        opacity: 1;
      }
    }
    .slick-next {
      right: 45%;
      @media (max-width: $breakpoint-sm) {
        right: 35%;
      }
    }
    .slick-prev {
      left: 45%;
      @media (max-width: $breakpoint-sm) {
        left: 35%;
      }
    }
  }
  .gallery-item-holder {
    position: relative;
    .ant-btn {
      position: absolute;
      z-index: 2;
      right: 20px;
      bottom: 20px;
      font-size: 18px;
    }
  }
}

.gallery-container {
  height: 100vh;
  .slick-slider, .slick-list, .slick-track{
    height: 100%;
  }
  .slick-slide{
    display: flex;
    align-items: center;
  }
  .slick-track{
    background-size: contain;
  }
}

.modal-gallery {
  .ant-modal-close {
    right: -40px;
    top: -40px;
    color: white;
  }
  .ant-modal-content {
    border-radius: 0 20px 20px 0;
    @media (max-width: $breakpoint-sm) {
      border-radius: 0 0 20px 20px;
    }
  }
}
