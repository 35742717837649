@import "../../../styles/shared";

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.logo {
  height: 32px;
  margin: 16px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: bold;
  text-transform: uppercase;
}

.LayoutAdmin {
  height: 100vh;
  .ant-layout-header {
    padding: 0 16px;
  }
  .ant-layout-content {
    max-height: calc(100vh - 65px);
    padding: 16px;
    .site-layout-background {
      padding: 20px;
    }
  }
  .header-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    height: 100%;

    &__left {
      align-items: center;
      display: flex;
    }
  }

  &__current-page {
    padding: 16px 16px 0;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 90%;
    &__footer {
      margin-top: auto;
    }
  }
}
